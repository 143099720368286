export enum PRODUCT {
  ONETIME_DETECTORS_10_DOLLARS_10K_WORDS = '7649b99f-ac67-4203-814a-245ba3d61a2d',
  ONETIME_DETECTORS_20_DOLLARS_20K_WORDS = 'dd3cde1a-1193-43de-a666-73eb3d06597d',
  ONETIME_DETECTORS_40_DOLLARS_50K_WORDS = '52f2c29e-db41-4c4a-b1f2-e6ced91487c3',
  ONETIME_DETECTORS_80_DOLLARS_100K_WORDS = 'db7b5e57-1ea6-46f0-b0bf-efa7613405c9',
  ONETIME_DETECTORS_100_DOLLARS_200K_WORDS = 'd6c2ec10-f1e0-489d-8bf6-3b90f7949194',
  ANNUAL_SUB_OFFER = 'ce6e8796-b540-434a-a45b-b783a872f897',
  UNBLOCK_DETECTOR_TOOLS_20_DOLLARS = '7c70a459-57cc-42ad-b2f2-bc6aa677c1c4',
  UNBLOCK_DETECTOR_TOOLS_10_DOLLARS = 'ec2d5585-a1e2-4956-b528-e70479300f7e',
  UNBLOCK_DETECTOR_TOOLS_7_5_DOLLARS = '62e651da-158e-4c25-840d-c3adc03cf1ff',
}

export enum PRODUCT_TAG {
  FREEMIUM_DETECTOR_LIMIT = 'freemium_detectors_limit',
  EXCLUDE_DETECTORS = 'exclude_detectors',
}
