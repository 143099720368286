// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": `#000`,
	"white": `#fff`,
	"primary_20": `#00164f`,
	"primary_30": `#13307e`,
	"primary_35": `#2f4990`,
	"primary_40": `#3b5aae`,
	"primary_50": `#708de4`,
	"primary_80": `#dbe1ff`,
	"gradient_40": `linear-gradient(90deg, #00B39B, #3B71F7)`,
	"error_20": `#410001`,
	"error_30": `#7d0008`,
	"error_40": `#e24d39`,
	"error_50": `#ff8a7c`,
	"error_80": `#ffdad5`,
	"neutral_20": `#46474a`,
	"neutral_30": `#76777a`,
	"neutral_40": `#aeafb1`,
	"neutral_60": `#dbdcdf`,
	"neutral_80": `#eef0f5`,
	"warning_40": `#f9e0a2`,
	"warning_50": `#f5d174`,
	"warning_60": `#efb217`,
	"warning_80": `#ffefc8`,
	"warning_90": `#fff8e5`,
	"green_30": `#006b5d`,
	"green_50": `#00b39b`,
	"green_70": `#80d9cd`,
	"green_90": `#ccf0eb`
};
export default ___CSS_LOADER_EXPORT___;
