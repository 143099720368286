// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jxnabmYr0UZHHGCfsH5w{font-size:14px;font-weight:600;letter-spacing:.1px;padding:10px 24px !important}@media(max-width: 1023px){.jxnabmYr0UZHHGCfsH5w{padding:16px 24px !important}}.F3UJiuHqvv0vyXLyFt9U{background-color:#f5d174 !important}.hl3Rzn3zYeuxGmNNi1e0{pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/layouts/MainLayout/components/ButtonSuggestion/styles.module.scss","webpack://./src/styles/_breakpoints.scss"],"names":[],"mappings":"AAIA,sBACE,cAAA,CACA,eAAA,CACA,mBAAA,CACA,4BAAA,CCME,0BDVJ,sBAOI,4BAAA,CAAA,CAIJ,sBACE,mCAAA,CAGF,sBACE,mBAAA","sourcesContent":["@import \"src/styles/breakpoints\";\n@import \"src/styles/mixins\";\n@import \"src/styles/colors\";\n\n.button {\n  font-size: 14px;\n  font-weight: 600;\n  letter-spacing: 0.1px;\n  padding: 10px 24px !important;\n\n  @include breakpoint(md) {\n    padding: 16px 24px !important;\n  }\n}\n\n.button--redemption {\n  background-color: $warning-50 !important;\n}\n\n.button--redemption-pending {\n  pointer-events: none;\n}","@mixin breakpoint($class) {\n  @if $class == xxs {\n    @media (max-width: 560px) {\n      @content;\n    }\n  }\n\n  @if $class == xs {\n    @media (max-width: 719px) {\n      @content;\n    }\n  }\n\n  @if $class == md {\n    @media (max-width: 1023px) {\n      @content;\n    }\n  }\n\n  @if $class == l {\n    @media (max-width: 1279px) {\n      @content;\n    }\n  }\n\n  @if $class == xl {\n    @media (max-width: 1439px) {\n      @content;\n    }\n  }\n\n  @if $class == xxl {\n    @media (max-width: 1919px) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `jxnabmYr0UZHHGCfsH5w`,
	"button--redemption": `F3UJiuHqvv0vyXLyFt9U`,
	"button--redemption-pending": `hl3Rzn3zYeuxGmNNi1e0`
};
export default ___CSS_LOADER_EXPORT___;
