export const ToolsSidebarKey = 'tools';

export const ToolsGalleryPath = '/tools';

export const ToolsGalleryTitle = 'Tools';

export const AdvancedArticleGeneratorPath = '/tools/advanced_article_generator';

export const AdvancedArticleGeneratorTitle = 'Long Article (1000+ Words)';

export const PaperWriterPath = '/tools/paper_writer';

export const PaperWriterTitle = 'Paper Writer';

export const PlagiarismCheckerPath = '/tools/plagiarism_checker';

export const PlagiarismCheckerTitle = 'Plagiarism Checker';

export const AiDetectorPath = '/tools/ai_content_detector';

export const AiDetectorTitle = 'AI Content Detector';

export const AiHumanizerPath = '/tools/ai_text_humanizer';

export const AiHumanizerTitle = 'AI Text Humanizer';

export const ParaphraseTextPath = '/tools/paraphrase_text';

export const ParaphraseTextTitle = 'Paraphrase Text';

export const SummariseTextPath = '/tools/summarize_text';

export const SummariseTextTitle = 'Summarise Text';

export const RewriteArticlePath = '/tools/rewrite_article';

export const RewriteArticleTitle = 'Rewrite Article';

export const ToolPath = '/tools/:slug';

export const ToolTitle = 'Tools item';

export const validExtensions = ['docx', 'txt', 'pdf'];
export const MAX_FILE_SIZE = 10000000; //10MB

export const GRAMMAR_CHECK_TOOL_ID = '3a72d3d8-77e0-423e-9cf6-de3b346e0235';
export const SUMMARIZE_TEXT_TOOL_ID = '8928337a-ca77-4fd2-a161-b5572b508fa6';

export const FORM_SUBMIT_BUTTON_TEXT: Record<string, string> = {
  [GRAMMAR_CHECK_TOOL_ID]: 'Check',
};

export const WIDE_FORM_REQUEST_TOOLS = [GRAMMAR_CHECK_TOOL_ID];

export const AVAILABLE_IN_EXTENSION_TOOL_ID = [
  GRAMMAR_CHECK_TOOL_ID,
  SUMMARIZE_TEXT_TOOL_ID,
];
