import { BUTTON_SUGGESTION } from '~/components/layouts/MainLayout/components/ButtonSuggestion';
import { REGULARITY } from '~/types/product';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

export const getButtonSuggestion = (
  subStatus: SUBSCRIPTION_STATUS,
  regularity: REGULARITY,
  isExtensionInstalled: boolean,
  isStripeUser: boolean,
): BUTTON_SUGGESTION | null => {
  if (
    !isStripeUser &&
    [
      SUBSCRIPTION_STATUS.CANCEL_PENDING,
      SUBSCRIPTION_STATUS.CANCELLED,
    ].includes(subStatus)
  ) {
    return BUTTON_SUGGESTION.RESTORE;
  }

  if (!isStripeUser && subStatus === SUBSCRIPTION_STATUS.REDEMPTION) {
    return BUTTON_SUGGESTION.REDEMPTION;
  }

  if (
    !isStripeUser &&
    subStatus === SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_PENDING
  ) {
    return BUTTON_SUGGESTION.REDEMPTION_PENDING;
  }

  if (
    !isStripeUser &&
    subStatus === SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_FAILED
  ) {
    return BUTTON_SUGGESTION.REDEMPTION_FAILED;
  }

  if (!isStripeUser && regularity !== REGULARITY.YEAR) {
    return BUTTON_SUGGESTION.UPGRADE;
  }

  if (!isExtensionInstalled) {
    return BUTTON_SUGGESTION.EXTENSION;
  }

  return null;
};
