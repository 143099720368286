// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WJFBrL3ifKm958WhjyFV{margin-bottom:24px !important}.V_2BdnfInO1o_cSdMxNK{color:#000;margin-bottom:16px !important}._vVFCDcBNTDH1oDmZejg{margin-bottom:24px}.gDn6VtPEsdMyWyifYfsz{margin-bottom:32px}.hILpC6F99LW2NRPm8qEF{margin-top:10px !important;text-align:center}.hILpC6F99LW2NRPm8qEF .FTJ35g37wayElDILNWs0{color:#3b5aae;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/modules/settings/components/modals/ChooseSubscriptionModal/views/RestoreSub/variants/StatsBenefitsVariant/styles.module.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAGA,sBACE,6BAAA,CAGF,sBACE,UCRM,CDSN,6BAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,0BAAA,CACA,iBAAA,CAEA,4CACE,aCnBS,CDoBT,eAAA","sourcesContent":["@import \"src/styles/mixins\";\n@import \"src/styles/colors\";\n\n.title {\n  margin-bottom: 24px !important;\n}\n\n.text {\n  color: $black;\n  margin-bottom: 16px !important;\n}\n\n.benefits {\n  margin-bottom: 24px;\n}\n\n.product {\n  margin-bottom: 32px;\n}\n\n.need_more {\n  margin-top: 10px !important;\n  text-align: center;\n\n  .text__link {\n    color: $primary-40;\n    font-weight: 600;\n  }\n}\n","$black: #000000;\r\n$white: #FFFFFF;\r\n\r\n$primary-20: #00164F;\r\n$primary-30: #13307E;\r\n$primary-35: #2F4990;\r\n$primary-40: #3B5AAE;\r\n$primary-50: #708DE4;\r\n$primary-60: #A5B8F4;\r\n$primary-80: #DBE1FF;\r\n\r\n$gradient-40: linear-gradient(90deg, #00B39B, #3B71F7);\r\n\r\n$error-20: #410001;\r\n$error-30: #7D0008;\r\n$error-40: #E24D39;\r\n$error-50: #FF8A7C;\r\n$error-80: #FFDAD5;\r\n\r\n$neutral-20: #46474A;\r\n$neutral-30: #76777A;\r\n$neutral-40: #AEAFB1;\r\n$neutral-60: #DBDCDF;\r\n$neutral-80: #EEF0F5;\r\n\r\n$warning-40: #F9E0A2;\r\n$warning-50: #F5D174;\r\n$warning-60: #EFB217;\r\n$warning-80: #FFEFC8;\r\n$warning-90: #FFF8E5;\r\n\r\n$green-30: #006B5D;\r\n$green-50: #00B39B;\r\n$green-70: #80D9CD;\r\n$green-90: #CCF0EB;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `WJFBrL3ifKm958WhjyFV`,
	"text": `V_2BdnfInO1o_cSdMxNK`,
	"benefits": `_vVFCDcBNTDH1oDmZejg`,
	"product": `gDn6VtPEsdMyWyifYfsz`,
	"need_more": `hILpC6F99LW2NRPm8qEF`,
	"text__link": `FTJ35g37wayElDILNWs0`
};
export default ___CSS_LOADER_EXPORT___;
