import React, { FC, useEffect, useState } from 'react';

import FullscreenPreloader from '~/components/atoms/FullscreenPreloader';
import { getRootDomain } from '~/helpers/getRootDomain';
import analytics from '~/services/analytics';
import sessionStorage from '~/services/storage/sessionStorage';
import { useActions } from '~/store/hooks/useActions';

const AUTO_LOGIN_COMPLETE_KEY = 'auto-login-complete';

type Props = {
  children?: React.ReactNode;
};

const CheckAuthWrapper: FC<Props> = ({ children }) => {
  const { checkIsUserLoggedIn } = useActions();
  const [isLoading, setIsLoading] = useState(true);

  const load = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await checkIsUserLoggedIn();
      if (
        document.referrer &&
        !sessionStorage.getByKey(AUTO_LOGIN_COMPLETE_KEY)
      ) {
        const refererHost = new URL(document.referrer).host;
        if (
          refererHost !== document.location.host &&
          getRootDomain(refererHost) === getRootDomain()
        ) {
          sessionStorage.setByKey(AUTO_LOGIN_COMPLETE_KEY, '1');
          analytics.trackEvent('login - auto authorization from landing');
        }
      }
      analytics.trackEvent('login - load private page');
    } catch (e: any) {
      console.error('CheckAuthWrapper', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return isLoading ? <FullscreenPreloader /> : <>{children}</>;
};
export { CheckAuthWrapper };
