import React, { ReactElement, useMemo } from 'react';

import { Icon as SidebarIcon } from '@iconify/react';
import { flowRight } from 'lodash';

import router, { RouteItemType } from '~/core/router';
import { sidebar } from '~/core/sidebar';
import {
  withHomeScreenRetentionLayout,
  withMainLayout,
  withMainSimpleLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  AdvancedArticleGeneratorPath,
  AdvancedArticleGeneratorTitle,
  AiDetectorPath,
  AiDetectorTitle,
  AiHumanizerPath,
  AiHumanizerTitle,
  PaperWriterPath,
  PaperWriterTitle,
  ParaphraseTextPath,
  ParaphraseTextTitle,
  PlagiarismCheckerPath,
  PlagiarismCheckerTitle,
  RewriteArticlePath,
  RewriteArticleTitle,
  SummariseTextPath,
  SummariseTextTitle,
  ToolPath,
  ToolsGalleryPath,
  ToolsGalleryTitle,
  ToolsSidebarKey,
  ToolTitle,
} from '~/modules/tools/constants';
import { useAppSelector } from '~/store/hooks/useAppSelector';

const ToolsGallery = lazyComponentLoader(() => import('./views/ToolsGallery'));
const Tool = lazyComponentLoader(() => import('./views/Tool'));

const AdvancedArticleGenerator = lazyComponentLoader(
  () => import('./views/AdvancedArticleGenerator'),
);

const PlagiarismChecker = lazyComponentLoader(
  () => import('./views/variant2/PlagiarismChecker'),
);

const AiDetector = lazyComponentLoader(
  () => import('./views/variant2/AiDetector'),
);

const AiDetectorV2 = lazyComponentLoader(
  () => import('./views/variant2/AiDetectorV2'),
);

const AiHumanizer = lazyComponentLoader(
  () => import('./views/variant2/AiHumanizer'),
);

const AiHumanizerV2 = lazyComponentLoader(
  () => import('./views/variant2/AiHumanizerV2'),
);

const ParaphraseText = lazyComponentLoader(
  () => import('./views/variant2/ParaphraseText'),
);

const SummarizeText = lazyComponentLoader(
  () => import('./views/variant2/SummarizeText'),
);

const RewriteArticle = lazyComponentLoader(
  () => import('./views/variant2/RewriteArticle'),
);

const ToolsGalleryRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(ToolsGallery),
  path: ToolsGalleryPath,
  title: ToolsGalleryTitle,
};

const AdvancedArticleGeneratorRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AdvancedArticleGenerator),
  path: AdvancedArticleGeneratorPath,
  title: AdvancedArticleGeneratorTitle,
};

const PaperWriterRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AdvancedArticleGenerator),
  path: PaperWriterPath,
  title: PaperWriterTitle,
};

const PlagiarismCheckerRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(PlagiarismChecker),
  path: PlagiarismCheckerPath,
  title: PlagiarismCheckerTitle,
};

const AiDetectorRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(() => {
    const { backend_ab_tests } = useAppSelector((state) => state.profile);
    const isDetectorV2 = backend_ab_tests?.experiment_ai_detector === 'v2';
    return isDetectorV2 ? <AiDetectorV2 /> : <AiDetector />;
  }),
  path: AiDetectorPath,
  title: AiDetectorTitle,
};

const AiHumanizerRoute: RouteItemType = {
  Component: () => {
    const { backend_ab_tests } = useAppSelector((state) => state.profile);
    const isHumanizerV2 =
      backend_ab_tests?.experiment_humanizer_v2?.includes('v2');

    const ComponentToRender = isHumanizerV2 ? AiHumanizerV2 : AiHumanizer;

    const WrappedComponent = useMemo(() => {
      const layouts = [
        withPWAModalLayout,
        withSubModalsLayout,
        withOnboardingLayout,
        withHomeScreenRetentionLayout,
        ...(isHumanizerV2 ? [withMainSimpleLayout] : [withMainLayout]),
      ];

      return flowRight(...layouts)(ComponentToRender);
    }, [isHumanizerV2]);

    return <WrappedComponent />;
  },
  path: AiHumanizerPath,
  title: AiHumanizerTitle,
};

const ParaphraseTextRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(ParaphraseText),
  path: ParaphraseTextPath,
  title: ParaphraseTextTitle,
};

const SummarizeTextRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(SummarizeText),
  path: SummariseTextPath,
  title: SummariseTextTitle,
};

const RewriteArticleRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(RewriteArticle),
  path: RewriteArticlePath,
  title: RewriteArticleTitle,
};

export const ToolRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(Tool),
  path: ToolPath,
  title: ToolTitle,
};

router.addPrivateRoutes([
  ToolsGalleryRoute,
  AdvancedArticleGeneratorRoute,
  PaperWriterRoute,
  PlagiarismCheckerRoute,
  AiDetectorRoute,
  AiHumanizerRoute,
  ParaphraseTextRoute,
  SummarizeTextRoute,
  RewriteArticleRoute,
  ToolRoute,
]);

sidebar.addItems({
  a: [
    {
      order: 2,
      key: ToolsSidebarKey,
      path: ToolsGalleryRoute.path,
      title: ToolsGalleryRoute.title,
      Icon: ({ isActive }): ReactElement => {
        return (
          <SidebarIcon
            icon={
              isActive
                ? `material-symbols:library-books`
                : `material-symbols:library-books-outline`
            }
          />
        );
      },
    },
  ],
});
