import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { PRODUCT } from '~/constants/products';
import { Typographies } from '~/theme/typography';
import { REGULARITY } from '~/types/product';
import { snakeCaseToCapitalizeCase } from '~/utils/text';

import styles from './styles.module.scss';

type Props = {
  id: string;
  title: string;
  price: number;
  regularity?: REGULARITY;
  description?: string;
};

const PaymentInfo: FC<Props> = ({
  id,
  title,
  price,
  regularity,
  description,
}) => {
  return (
    <div className={styles.container}>
      {id === PRODUCT.ANNUAL_SUB_OFFER && (
        <div className={styles.discount}>-$200</div>
      )}

      <div className={styles.flex}>
        <Typography
          className={styles.title}
          variant={Typographies.BODY_LARGE}
          component="p"
        >
          {title}
        </Typography>

        <div className={styles.box}>
          <Typography
            className={styles.price}
            variant={Typographies.BODY_LARGE}
            component="p"
          >
            Total: ${price / 100}
          </Typography>

          {regularity && (
            <Typography
              className={styles.regularity}
              variant={Typographies.TITLE_SMALL}
              component="p"
            >
              {regularity === REGULARITY.ONETIME
                ? 'One time payment'
                : `Per ${snakeCaseToCapitalizeCase(
                    regularity,
                  )}, Billed ${snakeCaseToCapitalizeCase(regularity)}ly`}
            </Typography>
          )}
        </div>
      </div>

      {description && (
        <Typography
          className={styles.description}
          variant={Typographies.TITLE_SMALL}
          component="p"
        >
          {description}
        </Typography>
      )}
    </div>
  );
};

export default PaymentInfo;
