import React, { FC, useEffect } from 'react';

import { useModalContext } from '~/contexts/modal/ModalContext';
import SubscriptionDetailsModal from '~/modules/settings/components/modals/SubscriptionDetailsModal';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

type Props = {
  children?: React.ReactNode;
};

const SubRebillWrapper: FC<Props> = ({ children }) => {
  const profile = useAppSelector((state) => state.profile);

  const { updateUserExtraFields } = useActions();
  const { handleOpenModal } = useModalContext();

  useEffect(() => {
    const isNotificationShown =
      profile.extra_fields?.notifications?.sub_rebill_modal;
    const isActiveSub =
      profile.user_subscription.status === SUBSCRIPTION_STATUS.ACTIVE;
    const isStillInTrial = profile.user_subscription.is_trial;
    const isTrialProduct = profile.user_product.has_trial_period;

    if (
      !profile.isLoggedIn ||
      !isActiveSub ||
      !isTrialProduct ||
      isNotificationShown ||
      isStillInTrial
    ) {
      return;
    }

    handleOpenModal({
      hideCloseButton: true,
      disableBackdropClick: true,
      component: ({ onClose }) => (
        <SubscriptionDetailsModal
          onClose={(): void => {
            updateUserExtraFields({
              notifications: {
                ...profile.extra_fields?.notifications,
                sub_rebill_modal: 1,
              },
            });

            onClose();
          }}
          planDetails={{ name: profile.user_subscription.subscription_name }}
        />
      ),
    });
  }, [profile]);

  return <>{children}</>;
};

export { SubRebillWrapper };
