import React, { FC, useEffect, useRef, useState } from 'react';

import { Typography } from '@mui/material';
import Payment from '@solidgate/react-sdk';
import clsx from 'clsx';
import 'swiper/swiper.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import Api from '~/Api';
import Loader from '~/components/atoms/Loader';
import PaymentError from '~/components/molecules/PaymentError';
import Agreement from '~/components/organisms/PaymentForm/components/Agreement';
import Guarantee from '~/components/organisms/PaymentForm/components/Guarantee';
import PaymentButton from '~/components/organisms/PaymentForm/components/PaymentButton';
import PaymentInfo from '~/components/organisms/PaymentForm/components/PaymentInfo';
import PaymentSystems from '~/components/organisms/PaymentForm/components/PaymentSystems';
import UpdatePaymentDescription from '~/components/organisms/PaymentForm/components/UpdatePaymentDescription';
import {
  sliderFormParams,
  sliderFormStyles,
} from '~/components/organisms/PaymentForm/formConfig';
import { ModalStateType } from '~/contexts/modal/ModalContext';
import useErrors from '~/hooks/useErrors';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';
import {
  AnalyticPaymentMethods,
  MerchantData,
  PAYMENT_METHODS,
  SOLID_PAYMENT_METHOD_MAPPER,
} from '~/types/payment';

import styles from './styles.module.scss';
import {
  handleAlreadyProcessed,
  handlePaypalPayment,
  renderPaypalButton,
} from '../paypalMethods';

export const paymentFormModalConfig: Partial<ModalStateType> = {
  isFullScreen: false,
  maxWidth: '800px',
  closeButtonColor: '#B3B3B3',
  pageViewMobile: true,
};

type Props = {
  onSuccessPayment: () => void | Promise<void>;
};

const PaymentMethodUpdateForm: FC<Props> = ({ onSuccessPayment }) => {
  const [activeCard, setActiveCard] = useState<PAYMENT_METHODS>(
    PAYMENT_METHODS.ONE_PAY,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isFormShown, setIsFormShown] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [paypalScript, setPaypalScript] = useState('');
  const [merchantData, setMerchantData] = useState<MerchantData | null>(null);

  const paypalBtnRef = useRef<HTMLDivElement | null>(null);
  const appleContainerRef = useRef<HTMLDivElement | null>(null);
  const { reportUserErrors } = useErrors();

  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const handlePaymentButtonClick = (type: PAYMENT_METHODS): void => {
    analytics.trackEvent('platform checkout - navigation', { method: type });
    setActiveCard(type);
  };

  const handleCloseError = (): void => {
    setPaymentError('');
  };

  const handleOnSuccess = async (): Promise<void> => {
    await onSuccessPayment();
    setIsLoading(false);
  };

  const handleOnFail = (message, method: AnalyticPaymentMethods): void => {
    analytics.trackEvent('platform checkout - error', {
      method,
      error: message,
    });

    setIsLoading(false);
    setMerchantData(null);
    setPaypalScript('');
    setPaymentError(message);
  };

  const handleOnSubmit = (method: AnalyticPaymentMethods): void => {
    analytics.trackEvent('platform checkout - submit', {
      method,
    });

    setIsLoading(true);
  };

  useEffect(() => {
    if (!merchantData) {
      (async (): Promise<void> => {
        try {
          const country = await Api.getCountry();
          const data = await Api.updatePaymentMethodCard(country);

          setMerchantData({ ...data, paymentIntent: data.payment_intent });
        } catch (e: any) {
          const errorMessage = e.error || e.message || 'Error :(';
          const isPaymentError = e.message.includes('createPaymentIntentCard');
          reportUserErrors({
            error: e,
            method: 'createPaymentIntentCard',
            userMessage: isPaymentError
              ? 'Card payment initiation failed. Please re-enter your details or'
              : errorMessage,
          });
        }
      })();
      return;
    }

    if (!paypalScript) {
      (async (): Promise<void> => {
        try {
          const paypalData = await Api.updatePaymentMethodPaypal();

          setPaypalScript(paypalData.pay_form.script_url);
          renderPaypalButton(paypalData.pay_form.script_url);
        } catch (e: any) {
          const isPaymentError = e.message.includes(
            'createPaymentIntentPaypal',
          );
          reportUserErrors({
            error: e,
            method: 'createPaymentIntentPaypal',
            userMessage: isPaymentError
              ? 'Paypal payment initiation unsuccessful. Try again or'
              : e.error || e.message || 'Error :(',
          });
        }
      })();
    }
  }, [merchantData, paypalScript]);

  useEffect(() => {
    paypalBtnRef.current?.addEventListener('order-started-processing', () =>
      handleOnSubmit(PAYMENT_METHODS.PAYPAL),
    );

    paypalBtnRef.current?.addEventListener(
      'order-processed',
      handlePaypalPayment,
    );

    paypalBtnRef.current?.addEventListener(
      'order-already-processed',
      handleAlreadyProcessed,
    );

    return (): void => {
      paypalBtnRef.current?.removeEventListener(
        'order-started-processing',
        () => handleOnSubmit(PAYMENT_METHODS.PAYPAL),
      );

      paypalBtnRef.current?.removeEventListener(
        'order-processed',
        handlePaypalPayment,
      );

      paypalBtnRef.current?.removeEventListener(
        'order-already-processed',
        handleAlreadyProcessed,
      );
    };
  }, [paypalBtnRef.current]);

  useEffect(() => {
    const handlePaypalIframeEvent = async (
      mes: Record<string, any>,
    ): Promise<void> => {
      const { payment_status, method, error_message } = mes.data;

      if (payment_status === 'error') {
        await handleOnFail(error_message, method);
      }

      if (payment_status === 'info' || payment_status === 'success') {
        await handleOnSuccess();
      }
    };

    window.addEventListener('message', handlePaypalIframeEvent);

    return (): void => {
      window.removeEventListener('message', handlePaypalIframeEvent);
    };
  }, []);

  useEffect(() => {
    analytics.trackEvent('platform checkout - payment update view');
  }, []);

  return (
    <>
      {(isLoading || !isFormShown) && <Loader />}

      <div className={styles.outer_wrapper}>
        <div
          className={clsx(styles.inner_wrapper, {
            [styles.is_shown]: isFormShown,
          })}
        >
          {isDesktop && (
            <div className={styles.description}>
              <UpdatePaymentDescription />
            </div>
          )}

          <div className={styles.form_outer}>
            <div className={styles.form_inner}>
              <Typography
                className={styles.title}
                variant={Typographies.LABEL_LARGE}
                component="h3"
              >
                Select your payment method
              </Typography>

              {paymentError && (
                <PaymentError
                  paymentError={paymentError}
                  handleCloseError={handleCloseError}
                />
              )}

              {merchantData && !paymentError && (
                <Swiper
                  slidesPerView={1}
                  spaceBetween={16}
                  initialSlide={0}
                  centeredSlides
                  onSwiper={(swiper): void => {
                    activeCard === PAYMENT_METHODS.ONE_PAY &&
                      swiper.slideNext(); // By invoking this method we trigger slider for IOS Chrome
                  }}
                  className={clsx(styles.slider, {
                    [styles.is_disabled]: isLoading,
                  })}
                >
                  <div className={styles.buttons}>
                    {[PAYMENT_METHODS.ONE_PAY, PAYMENT_METHODS.CARD].map(
                      (type) => (
                        <PaymentButton
                          key={type}
                          isActive={activeCard === type}
                          onClick={handlePaymentButtonClick}
                          type={type}
                        />
                      ),
                    )}
                  </div>

                  <SwiperSlide className={styles.slide}>
                    <Typography
                      className={styles.card_title}
                      variant={Typographies.LABEL_LARGE}
                      component="p"
                    >
                      Card details
                    </Typography>

                    <Payment
                      applePayContainerRef={appleContainerRef}
                      merchantData={merchantData}
                      formParams={sliderFormParams}
                      styles={sliderFormStyles}
                      onFail={(e): void =>
                        handleOnFail(
                          e.message,
                          SOLID_PAYMENT_METHOD_MAPPER[e.entity],
                        )
                      }
                      onSuccess={handleOnSuccess}
                      onMounted={(): void => {
                        window.setTimeout(() => {
                          setIsFormShown(true);
                        }, 500);
                      }}
                      onSubmit={(e): void =>
                        handleOnSubmit(SOLID_PAYMENT_METHOD_MAPPER[e.entity])
                      }
                    />
                  </SwiperSlide>

                  <SwiperSlide className={styles.slide}>
                    <div
                      className={styles['slide__pay_button']}
                      ref={appleContainerRef}
                    />
                    <div
                      className={styles['slide__pay_button']}
                      id="paypal-button"
                      ref={paypalBtnRef}
                    />
                  </SwiperSlide>
                </Swiper>
              )}

              <Guarantee />
              <PaymentInfo
                id=""
                price={0}
                title="Payment Method Update"
                description="Updated method will be used for billing your existing plan"
              />
              <PaymentSystems />
            </div>
            <Agreement />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodUpdateForm;
