export enum AB_TESTS_KEYS {
  PWA_COMMUNICATION_V4_1 = 'pwa-v4.1-re-test',
  CHAT_ONBOARDING = 'chat_onboarding',
}

export const AB_TEST_FEATURES: Record<
  AB_TESTS_KEYS,
  {
    key: AB_TESTS_KEYS;
    variants: {
      control: string;
      variant_1: string;
      [key: string]: string;
    };
    defaultVariant: string;
  }
> = {
  [AB_TESTS_KEYS.PWA_COMMUNICATION_V4_1]: {
    key: AB_TESTS_KEYS.PWA_COMMUNICATION_V4_1,
    variants: {
      control: '4.1',
      variant_1: '4.0',
    },
    defaultVariant: '4.1',
  },
  [AB_TESTS_KEYS.CHAT_ONBOARDING]: {
    key: AB_TESTS_KEYS.CHAT_ONBOARDING,
    variants: {
      control: 'control',
      variant_1: 'tested',
    },
    defaultVariant: 'control',
  },
};
