export enum REGULARITY {
  ONETIME = 'onetime',
  WEEK = 'week',
  MONTH = 'month',
  HALF_YEAR = 'half-year',
  YEAR = 'year',
}

export interface ProductType {
  created_at: string;
  is_unlimited: boolean;
  currency: string;
  has_trial_period: boolean;
  id: string;
  is_solid: boolean;
  name: string;
  price: number;
  subscription_type: string;
  trial_amount: number;
  trial_currency: string;
  trial_period: number;
  trial_words_amount: number;
  updated_at: string;
  words_amount: number;
  regularity: REGULARITY;
  new_price_ui: number;
  old_price_ui: number;
  discount_percentage_ui: number;
  regularity_ui: REGULARITY;
  description_ui: string;
  details_ui: string;
  billing_period?: number;
  is_popular_ui: boolean;
  tags: string[];
}

export enum LIMITED_TOOL {
  ai_content_detector = 'ai_content_detector',
  plagiarism_checker = 'plagiarism_checker',
  ai_text_humanizer = 'ai_text_humanizer',
}
