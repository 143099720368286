import React, { FC, useState } from 'react';

import { Icon } from '@iconify/react';

import LoadingButton from '~/components/atoms/buttons/LoadingButton';

import styles from './styles.module.scss';

type Props = {
  onClick: () => Promise<void>;
};

const RestoreButton: FC<Props> = ({ onClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await onClick();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingButton
      disabled={isLoading}
      loading={isLoading}
      fullWidth
      color="secondary"
      variant="contained"
      startIcon={<Icon icon="material-symbols:magic-button-outline" />}
      onClick={handleClick}
      className={styles.button}
    >
      Restore
    </LoadingButton>
  );
};

export default RestoreButton;
